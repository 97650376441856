const FIT_SELECTOR = '.js-height-fit'

/**
 * Calculate Fit Box in Window
 * @export
 * @class HeightFit
 */
export default class HeightFit {

  constructor() {
    this.fitSelectors = document.querySelectorAll(FIT_SELECTOR)
    this.init()
    window.addEventListener('resize', () => this.init())
  }

  init(el) {
    Array.from(this.fitSelectors, el => {
      this.calc(el)
    })
  }

  calc(el) {
    const windowHeight = document.documentElement.clientHeight || window.innerHeight
    el.style.minHeight = `${windowHeight}px`
  }

}