"use strict";
import slick from 'slick-carousel'

import picturefill from 'picturefill'
import OvserberAnimation from './modules/OvserberAnimation'
import Modal from './modules/Modal'
import HeightFit from './modules/HeightFit'
import TagManager from './modules/TagManager'

import Player from '@vimeo/player';

/**
 * @export
 * @class Main
 */
export default class Main {
  constructor() {
    this.init()
  }

  init() {
    this.picture()
    new OvserberAnimation()
    new HeightFit()
    new Modal()
    new TagManager()

    var scroll = new SmoothScroll('a[href*="#"]', {
    	speed: 300,
      updateURL: false
    });
  }

   picture() {
     picturefill()
   }
}

window.addEventListener('DOMContentLoaded', () => new Main());

(function($){
  // メニュー操作
  $('.js-header-menu-btn').on('click', function(){
    if( $(window).width() <= 768 ) {
      $(this).toggleClass('is-open');
      $('.js-header-menu-list').toggleClass('is-open');
      $('.js-header-menu').toggleClass('is-open');
    } else {
      $(this).toggleClass('is-open');
      $('.js-header-overlay').toggleClass('is-open');
      $('.js-header-menu-list').slideToggle(300).toggleClass('is-open');
      $('.js-header-menu').toggleClass('is-open');
    }
  });

  // 追従totop
  var totop = $('#js-totop'); // ボタンを指定
  totop.hide();
  var totop_mb = totop.css("bottom");
  $(window).on('scroll', function() {
    if ($(window).scrollTop() > 100) {
      totop.fadeIn();
    } else {
      totop.fadeOut();
    }
    var selfHeight = totop.innerHeight()
    var scrollHeight = $(document).height();
    var scrollPosition = $(window).height() + $(window).scrollTop();
    var footHeight = $("footer").innerHeight() - selfHeight * 1.5; // ここでフッターの高さを取得
    if ( scrollHeight - scrollPosition  <= footHeight ) { // フッター付近まで来たら
      totop.css({
      "position":"absolute",
      "bottom": footHeight // フッターの65px上で止まる
      });
    } else { // それ以外は画面下からtotop_mbの位置に固定
      totop.css({
        "position":"fixed",
        "bottom": totop_mb
      });
    }
  });

  // Top Slider
  $('.slider').slick({
    arrows:true,
    slidesToShow:1,
    responsive:[{
      breakpoint: 768,
      settings:{
        arrows: true,
      }
    }]
  });
  // creatorページスライダー
  $(window).on('load resize', function() {
    if( $(window).width() <= 768 ) {
      $('.js-creator-slider').not('.slick-initialized').slick({
        arrows:true,
        slidesToShow:1,
      });
    } else {
      $('.js-creator-slider.slick-initialized').slick('unslick');
    }
  });

  // RECRUITページ・アコーディオン
  $('.js-recruit-toggle-btn').on('click', function(){
    $(this).toggleClass('is-active');
    $(this).parent().parent().find('.recruit__item-inner').toggleClass('is-active');
  });


  // modal内の動画を止める
  $('.js-modal-video-stop').on('click', function() {
    var iframe = $(this).next().find('iframe')[0];
    var player = new Player(iframe);
    player.pause();
  });

})(jQuery);
