const MODAL         = '.js-modal'
const MODAL_TRIGGER = '.js-modal-trigger'
const SHOW_CLASS    = '-show'
const DATE_TARGET   = 'data-target'
const DATE_SCROLL   = 'data-scroll'
const EXPANDED      = 'aria-expanded'

/**
 * @export
 * @class Modal
 */
export default class Modal {

  constructor() {
    this.modal   = document.querySelectorAll(MODAL)
    this.trigger = document.querySelectorAll(MODAL_TRIGGER)
    this.init()
    this.handler()
    this.scroll()
  }

  init() {
    this.setOffset()
  }

  handler(el) {
    Array.from(this.trigger, el => {
      el.addEventListener('click', (el) => {
        const isExpanded = el.currentTarget.getAttribute(EXPANDED) === 'true'
        if (!isExpanded) {
          this.show(el)
          this.setFixed()
        } else {
          this.hide(el)
          this.cancelFixed()
        }
      })
    })
  }

  scroll() {
    window.addEventListener('scroll', () => this.setOffset(), false)
  }

  setOffset() {
    document.documentElement.setAttribute(DATE_SCROLL, `${window.pageYOffset}px`)
  }

  show(el) {
    const dataTarget = el.currentTarget.getAttribute(DATE_TARGET)
    const targetElm = document.getElementById(dataTarget)
    el.currentTarget.setAttribute(EXPANDED, 'true')
    targetElm.classList.add(SHOW_CLASS)
    const closeTrigger = targetElm.querySelectorAll(MODAL_TRIGGER)
    for (let i = 0; i < closeTrigger.length; i++) {
      closeTrigger[i].setAttribute(EXPANDED, 'true')
    }
  }

  hide(el) {
    const dataTarget = el.currentTarget.getAttribute(DATE_TARGET)
    const targetElm = document.getElementById(dataTarget)
    el.currentTarget.setAttribute(EXPANDED, 'false')
    const closeTrigger = targetElm.querySelectorAll(MODAL_TRIGGER)
    for (let i = 0; i < closeTrigger.length; i++) {
      closeTrigger[i].setAttribute(EXPANDED, 'false')
    }
    for (let i = 0; i < this.modal.length; i++) {
      this.modal[i].classList.remove(SHOW_CLASS)
    }
    for (let i = 0; i < this.trigger.length; i++) {
      this.trigger[i].setAttribute(EXPANDED, 'false')
    }
  }

  setFixed() {
    const scrollY = document.documentElement.getAttribute(DATE_SCROLL)
    const body = document.body
    body.style.position = 'fixed'
    body.style.top = `-${scrollY}`
  }

  cancelFixed() {
    const body = document.body
    const scrollY = body.style.top
    body.style.position = ''
    body.style.top = ''
    window.scrollTo(0, parseInt(scrollY || '0') * -1)
  }
}