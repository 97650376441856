/**
 * @export
 * @class TagManager
 */
export default class TagManager {

  constructor() {
    this.scrollObjSettings = [{
        id: 'ga-movie',
        event_category: 'movie'
      },
      {
        id: 'ga-twcp',
        event_category: 'twcp'
      },
      {
        id: 'ga-menu',
        event_category: 'menu'
      }
    ]

    for (let i = 0; i < this.scrollObjSettings.length; i++) {
      const id = this.scrollObjSettings[i].id
      const target = document.getElementById(id)
      if (target) {
        new ObserbeElement(
          document.getElementById(this.scrollObjSettings[i].id),
          this.scrollObjSettings[i].event_category
        )
      }
    }
  }
}

class ObserbeElement {
  constructor(elm, event_category) {
    this.elm = elm
    this.event_category = event_category

    this.io = new IntersectionObserver(this.onIntersect.bind(this), {
      rootMargin: '0px 0px 0px 0px'
    })
    this.io.observe(this.elm)
  }

  onIntersect(elm) {
    if (elm[0].isIntersecting) {
      // console.log('categoly:', this.event_category)
      gtag('event', 'scroll', {
        'event_category': this.event_category,
        'event_label': 'top'
      })
      this.io && this.io.disconnect()
      this.io = null
    }
  }
}
