const completeClassName = '-completed'

export default class ObserveCssAnimation{
  constructor(){
    const targets = Array.prototype.slice.call(document.getElementsByClassName('js-animate'))
    for( let i = 0 ; i < targets.length ; i++ )
    {
      new ObserbeElement(targets[i])
    }

  }
}

class ObserbeElement{
  constructor(elm){
    this.elm = elm;

    this.io = new IntersectionObserver(this.onIntersect.bind(this), { rootMargin: '-5% 0px -5% 0px' });
    this.io.observe(this.elm);
  }

  //
  onIntersect(elm){
    if (elm[0].isIntersecting) {
      this.addClass(elm[0].target)
      this.io && this.io.disconnect();
      this.io = null;
    }
  }

  addClass(elm){
    const className = elm.getAttribute('data-animation')
    elm.classList.add(className)
    elm.classList.add(completeClassName)
  }
}
